
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Oswald:wght@300;400&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background-color: #F5F5F5;
  font-family: Oswald, Helvetica, sans-serif;
  font-size: 1.25rem;
  line-height: 2em;
  margin-top: 12.5vh;
}

a {
  text-decoration: none;
}

h1 {
  margin-bottom: 1rem;
}

p {
  line-height: 1.5rem;
}

#header-nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-evenly;
  align-content: space-between;
  place-items: center;
  background-color: #A7C7E7;
  color: #072F5F;
  box-shadow: 0 0.1em 0.1em rgba(0, 0, 0, 0.15), 0 0.2em 0.2em rgba(0, 0, 0, 0.20);
  font-size: 1.5rem;
  font-weight: bold;
  width: 100%;
  height: 12.5vh;
  padding: 1em;
  z-index: 10;
}

#company-header {
  display: flex;
  align-items: center;
  height: auto;
  padding: 1em;
}

#company-logo {
  max-height: 10em;
}

.nav-link {
  background-color: #A7C7E7;
  font-size: x-large;
  font-weight: 600;
  text-underline: #000000;
  color: #072F5F;
  height: auto;
  width: auto;
  border: none;
  padding: 0.5em;
  margin: 0 0.25em;
  -webkit-transition: color 0.2s;
}

.nav-link:hover {
  color: #F5F5F5;
  cursor: pointer;
  -webkit-transition: color 0.3s;
}

.hover-underline-animation {
  display: inline-block;
  position: relative;
}

.hover-underline-animation:after {
  content: '';
  position: absolute;
  background-color: #F5F5F5;
  height: 3px;
  bottom: 0;
  left: 0;
  width: 100%;
  transform: scaleX(0);
  transform-origin: 50%;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(0.9);
  transform-origin: 50%;
}

.active-nav-link {
  color: #F5F5F5;
  pointer-events: none;
  transition: all 0.2s ease-in-out;
}

.slides-container {
  position: relative;
  text-align: center;
  background-color: #000000;
  width: 100%;
  height: 50vw;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.slide {
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 16%;
  user-select: none;
  background-color: #000000;
  width: 100%;
  transition: all 1s ease-out;
}

.slide-active {
  opacity: 1;
  transition: all 0.4s ease-out;
}

.household-property-img {
  background-color: #000000;
  height: 50vw;
  width: auto;
  margin: auto;
}

.slides-button {
  position: absolute;
  top: 45%;
  font-size: xx-large;
  background-color: #000000;
  color: #F5F5F5;
  opacity: 0;
  border-radius: 10%;
  transition: opacity 0.2s ease-in;
  padding: 0.5em;
}

#slides-button-left {
  left: 2.5vw;
}

#slides-button-right {
  right: 2.5vw;
}

@keyframes fade-in {
  100% { opacity: 1; }
}

@keyframes fade-out {
  100% {
    background: #F5F5F5;
    color: #000000;
  }
}

.page-enter {
  opacity: 0;
}

.page-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in-out;
}

.page-exit {
  opacity: 1;
}

.page-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}


#main-text-container {
  color: #072F5F;
  font-family: Oswald, Helvetica, serif;
  font-size: large;
  line-height: 1.5em;
  padding: 2vh 15vw;
  text-align: center;
}

.description-heading {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  background: #D3D3D3;
  width: 100%;
  padding: 1em;
  margin: 7.5vh 0 5vh 0;
}

h2 {
  line-height: 1.25em;
  padding: 1em 0;
}

p, ul, li {
  color: #000000;
  font-family: Helvetica, serif;
}

ul {
  line-height: 1.5em;
  padding: 0 2em;
}

li {
  padding: 0.5em 0;
}

/* availability page */

#properties {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 0 2 auto;
  justify-content: center;
  align-content: center;
  padding: 1em;
}

.property-tile {
  display: grid;
  grid-template-rows: 1fr auto auto;
  grid-template-columns: repeat(2, 1fr);
  border-radius: 0.3em;
  box-shadow: 0 0.2em 0.2em rgba(0, 0, 0, 0.19), 0 0.2em 0.2em rgba(0, 0, 0, 0.23);
  background: transparent;
  transition: border 0.2s ease-out;
  width: 40vw;
  height: auto;
  margin: 1em;
}

.property-image {
  grid-row: 1 / 3;
  grid-column: 1 / 3;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.property-image img {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  width: 40vw;
  height: auto;
}

.property-title {
  grid-row: 2 / 3;
  grid-column: 1 / 2;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.property-title p {
  display: grid;
  place-items: center;
  background: #F5F5F5;
  padding: 1rem;
  border-top-right-radius: inherit;
  border-top-left-radius: inherit;
}

.property-meta {
  grid-row: 3 / 4;
  grid-column: 1 / 3;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  padding: 0;
}

.property-description {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  background-color: #F5F5F5;
  color: #000;
  line-height: 1.5rem;
  max-width: 40vw;
  padding: 0.5rem 0 1.25rem 2rem;
}

.description-title {
  font-weight: bolder;
  width: 100%;
}

.property-icon-stats {
  display: inline-block;
}

.description-room-space {
  font-weight: normal;
  font-size: medium;
  margin: 0 1.5em 0 0;
}

.description-left-column {
  white-space: pre-wrap;
  width: 50%;
  line-height: 1.5rem;
  padding-right: 1.5em;
}

.description-right-column {
  font-size: medium;
  border-left: 0.1em dotted #000;
  line-height: 1.5em;
  width: 50%;
  padding-left: 1.5em;
}

.description-address {
  font-weight: normal;
  font-size: medium;
  width: 100%;
}

/* contact page */

#contact-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  background: #D3D3D3;
  width: 100%;
  padding: 4em;
  margin: 7.5vh 0 2.5vh 0;
}

#contact-header > h1 {
  flex: 0 1 100%;
  font-family: "Helvetica Neue", sans-serif;
  color: #1560bd;
}

#contact-header > address {
  flex: 0 2 auto;
  color: #000000;
  font-style: normal;
  justify-content: space-between;
  margin: 0 1vw;
}

fieldset {
  border: none;
}

#form-container {
  font-size: 1.5rem;
  line-height: 0;
  display: block;
  padding: 0 5em;
}

#survey-form legend {
  font-size: larger;
  font-weight: bold;
  text-align: center;
  line-height: 2rem;
  color: #072F5F;
  padding: 1em;
}

#personal-info {
  border-top: solid;
}

input, textarea {
  font-family: Arial, sans-serif;
  width: 100%;
  padding: 0.75em;
  margin: 0.5em;
}

textarea {
  height: 30vh;
}

#contact-submit {
  display: block;
  text-align: center;
  padding: 0.5em;
}

#submit {
  width: 17.5%;
  background-color: #072F5F;
  color: #F5F5F5;
  font-family: Arial, sans-serif;
  font-weight: bold;
  border: none;
  border-radius: 3px;
  padding: 1.5em;
  -webkit-transition: background-color ease-in 0.1s;
}

#submit:hover {
  cursor: pointer;
  background-color: #4682B4;
}

/* footer */

footer {
  border: 0.2em solid #000000;
  border-left: none;
  border-right: none;
  background-color: #000000;
  color: #F5F5F5;
  text-align: center;
  padding: 1em;
  margin-top: 5em;
}

@media (hover: hover) {
  .slides-container:hover .slides-button {
    opacity: 1;
    animation-name: fade-in;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }

  .slides-container:hover .slides-button:hover {
    cursor: pointer;
    user-select: none;
    opacity: 1;
    animation-name: fade-out;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }
}

@media (max-width: 900px) {
  #header-nav {
    justify-content: flex-start;
    height: 12.5vh;
  }

  #company-header {
    display: flex;
    height: auto;
    padding: 1rem 1rem 1rem 0;
  }

  #company-logo {
    max-height: 7.5em;
  }

  #nav-links {
    display: none;
  }

  .property-tile, .property-tile img, .property-title, .property-description {
    width: 90vw;
    max-width: 100%;
    padding-top: 0;
    padding-bottom: 0.5rem;
  }

  .description-left-column {
    width: 95%;
    padding: 0 0 1rem 0;
  }

  .description-right-column {
    width: 95%;
    border-left: none;
    border-top: 0.1em dotted #000;
    padding: 1rem 0 0 0;
  }

  #contact-header {
    padding: 2em;
  }

  #form-container {
    padding: 0 1.5em;
  }

  #submit {
    width: 50%;
  }
}
